@import '~antd/dist/antd.css';

.App {
  width: 100%;
  margin-top: 5rem;
}

.logo {
  padding: 0.5rem 1rem;
  width: 100%;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.powerbi-frame {
  height: 100%;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.zoom-controller {
  position: absolute;
  right: 20px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  min-width: 3.25rem;
  align-items: center;
  padding: 0.5rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(223, 223, 223);
}